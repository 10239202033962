import Slides from "../pages/slides";
import { graphql } from "gatsby";

export default Slides;

export const pageQuery = graphql`
query TemplateSlidesPage($skip: Int) {
  # Get tags
  tags: allSlidesYaml {
    group(field: tags) {
      fieldValue,
      totalCount
    }
  }

  # Get slides
  slides: allSlidesYaml(
    sort: { order: DESC, fields: [updatedDate, title] }
    limit: 20,
    skip: $skip
  ) {
    totalCount
    edges {
      node {
        id
        title
        tags
        createdDate(formatString: "DD MMMM, YYYY")
        updatedDate(formatString: "DD MMMM, YYYY")
        authors {
          name
          twitter
        }
        url
        thumbnail
        video
      }
    }
  }
}
`;
